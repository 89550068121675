/* eslint-disable import/no-unused-modules */
import { Interface } from '@ethersproject/abi'
// import { Contract } from '@ethersproject/contracts'
import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import FixedStakingRewardsJSON from 'abis/FixedStakingRewards.json'
import RingTokenClaimerJSON from 'abis/RingTokenClaimer.json'
import { SupportedChainId } from 'constants/chains'
// import { abi as STAKING_REWARDS_ABI } from '@uniswap/liquidity-staker/build/StakingRewards.json'
import { ChainId, Currency, CurrencyAmount, Token } from 'eth-mainnet-few-sdk-core-2'
import { Pair } from 'eth-mainnet-few-v2-sdk-3'
import { USDB_BLAST } from 'eth-mainnet-smart-order-router-4'
import { useCurrency } from 'hooks/Tokens'
import useCurrentBlockTimestamp from 'hooks/useCurrentBlockTimestamp'
import JSBI from 'jsbi'
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount'
import { ReactNode, useMemo } from 'react'
import { useUserClaimData } from 'state/claim/hooks'
import { useMultipleContractMultipleData } from 'state/fewMulticall/hooks'

import {
  AirdropType,
  DAI,
  DAI_SEPOLIA,
  FEW_BLAST,
  FEW_DAI,
  FEW_DETH,
  FEW_DUSD,
  FEW_JUICE,
  FEW_OMNI,
  FEW_ORBIT,
  FEW_ORIGINAL_BLAST,
  FEW_ORIGINAL_DETH,
  FEW_ORIGINAL_DUSD,
  FEW_ORIGINAL_JUICE,
  FEW_ORIGINAL_OMNI,
  FEW_ORIGINAL_ORBIT,
  FEW_ORIGINAL_RING,
  FEW_ORIGINAL_USDB,
  FEW_ORIGINAL_USDC,
  FEW_ORIGINAL_USDT,
  FEW_ORIGINAL_WBTC,
  FEW_RING,
  FEW_USDB,
  FEW_USDC,
  FEW_USDT,
  FEW_WBTC,
  FEW_WRAPPED_NATIVE_CURRENCY,
  nativeOnChain,
  POINT,
  POINT_MERKLE_DISTRIBUTOR_ADDRESS,
  RGB,
  RING,
  RING_CLAIMER_ADDRESS,
  USDB_BLAST_SEPOLIA,
  USDC_MAINNET,
  USDT,
  WRAPPED_NATIVE_CURRENCY,
} from '../../constants/tokens'

// const STAKING_REWARDS_INTERFACE = new Interface(STAKING_REWARDS_ABI)
// const STAKING_REWARDS_INTERFACE = new Interface(StakingRewardsJSON.abi)
const FEW_STAKING_REWARDS_INTERFACE = new Interface(FixedStakingRewardsJSON.abi)
const RING_TOKEN_CLAIMER_INTERFACE = new Interface(RingTokenClaimerJSON.abi)

export const STAKING_GENESIS = 1600387200

// export const REWARDS_DURATION_DAYS = 60

export const FEW_STAKING_GENESIS = 1677391280

export const FEW_REWARDS_DURATION_DAYS = 365

export const STAKING_REWARDS_INFO: {
  [chainId: number]: {
    tokens: [Token, Token]
    token?: Token
    originalTokens: [Token, Token]
    originalToken?: Token
    index: number
    key: number
    stakingRewardAddress: string
    ringClaimerAddress: string
    endTime: string
  }[]
} = {
  168587773: [
    {
      tokens: [
        FEW_WRAPPED_NATIVE_CURRENCY[SupportedChainId.BLAST_SEPOLIA] as Token,
        FEW_USDC[SupportedChainId.BLAST_SEPOLIA],
      ],
      originalTokens: [
        WRAPPED_NATIVE_CURRENCY[SupportedChainId.BLAST_SEPOLIA] as Token,
        FEW_ORIGINAL_USDC[SupportedChainId.BLAST_SEPOLIA],
      ],
      index: 1,
      key: 1,
      stakingRewardAddress: '0x8c90691F362B798d3bAFD3c68469f8aF5d5bBeED',
      ringClaimerAddress: RING_CLAIMER_ADDRESS[SupportedChainId.BLAST_SEPOLIA],
      endTime: '1707442335',
    },
  ],
}

// eslint-disable-next-line import/no-unused-modules
export const NEW_STAKING_REWARDS_INFO: {
  [chainId: number]: {
    tokens: [Token, Token]
    token?: Token
    originalTokens: [Token, Token]
    originalToken?: Token
    index: number
    key: number
    stakingRewardAddress: string
    ringClaimerAddress: string
    endTime: string
  }[]
} = {
  168587773: [
    {
      tokens: [
        FEW_WRAPPED_NATIVE_CURRENCY[SupportedChainId.BLAST_SEPOLIA] as Token,
        FEW_USDC[SupportedChainId.BLAST_SEPOLIA],
      ],
      originalTokens: [
        WRAPPED_NATIVE_CURRENCY[SupportedChainId.BLAST_SEPOLIA] as Token,
        FEW_ORIGINAL_USDC[SupportedChainId.BLAST_SEPOLIA],
      ],
      index: 2,
      key: 2,
      stakingRewardAddress: '0x366Ac78214aFE145Ca35d4A6513F4eD9e8909Fe8',
      ringClaimerAddress: RING_CLAIMER_ADDRESS[SupportedChainId.BLAST_SEPOLIA],
      endTime: '1708927280',
    },
  ],
  11155111: [
    {
      tokens: [FEW_WRAPPED_NATIVE_CURRENCY[SupportedChainId.SEPOLIA] as Token, FEW_USDC[SupportedChainId.SEPOLIA]],
      originalTokens: [
        WRAPPED_NATIVE_CURRENCY[SupportedChainId.SEPOLIA] as Token,
        FEW_ORIGINAL_USDC[SupportedChainId.SEPOLIA],
      ],
      index: 1,
      key: 9,
      stakingRewardAddress: '0x3f6d0620d655Dc618Cb956cc2073644f92736616',
      ringClaimerAddress: RING_CLAIMER_ADDRESS[SupportedChainId.SEPOLIA],
      endTime: '1719334988',
    },
    {
      tokens: [FEW_WRAPPED_NATIVE_CURRENCY[SupportedChainId.SEPOLIA] as Token, FEW_USDT[SupportedChainId.SEPOLIA]],
      originalTokens: [
        WRAPPED_NATIVE_CURRENCY[SupportedChainId.SEPOLIA] as Token,
        FEW_ORIGINAL_USDT[SupportedChainId.SEPOLIA],
      ],
      index: 3,
      key: 9,
      stakingRewardAddress: '0x3f6d0620d655Dc618Cb956cc2073644f92736616',
      ringClaimerAddress: RING_CLAIMER_ADDRESS[SupportedChainId.SEPOLIA],
      endTime: '1719296493',
    },
  ],
  81457: [
    {
      tokens: [FEW_WRAPPED_NATIVE_CURRENCY[SupportedChainId.BLAST] as Token, FEW_BLAST[SupportedChainId.BLAST]],
      originalTokens: [
        WRAPPED_NATIVE_CURRENCY[SupportedChainId.BLAST] as Token,
        FEW_ORIGINAL_BLAST[SupportedChainId.BLAST],
      ],
      index: 12,
      key: 21,
      stakingRewardAddress: '0xeff87a51f5abd015f1afcd5737bbab450ea15a24',
      ringClaimerAddress: RING_CLAIMER_ADDRESS[SupportedChainId.BLAST],
      endTime: '1726947651',
    },
    {
      tokens: [FEW_WRAPPED_NATIVE_CURRENCY[SupportedChainId.BLAST] as Token, FEW_USDB[SupportedChainId.BLAST]],
      originalTokens: [
        WRAPPED_NATIVE_CURRENCY[SupportedChainId.BLAST] as Token,
        FEW_ORIGINAL_USDB[SupportedChainId.BLAST],
      ],
      index: 3,
      key: 14,
      stakingRewardAddress: '0xeff87a51f5abd015f1afcd5737bbab450ea15a24',
      ringClaimerAddress: RING_CLAIMER_ADDRESS[SupportedChainId.BLAST],
      endTime: '1726947651',
    },
    {
      tokens: [FEW_WRAPPED_NATIVE_CURRENCY[SupportedChainId.BLAST] as Token, FEW_OMNI[SupportedChainId.BLAST]],
      originalTokens: [
        WRAPPED_NATIVE_CURRENCY[SupportedChainId.BLAST] as Token,
        FEW_ORIGINAL_OMNI[SupportedChainId.BLAST],
      ],
      index: 4,
      key: 15,
      stakingRewardAddress: '0xeff87a51f5abd015f1afcd5737bbab450ea15a24',
      ringClaimerAddress: RING_CLAIMER_ADDRESS[SupportedChainId.BLAST],
      endTime: '1726947651',
    },
    {
      tokens: [FEW_WRAPPED_NATIVE_CURRENCY[SupportedChainId.BLAST] as Token, FEW_JUICE[SupportedChainId.BLAST]],
      originalTokens: [
        WRAPPED_NATIVE_CURRENCY[SupportedChainId.BLAST] as Token,
        FEW_ORIGINAL_JUICE[SupportedChainId.BLAST],
      ],
      index: 5,
      key: 16,
      stakingRewardAddress: '0xeff87a51f5abd015f1afcd5737bbab450ea15a24',
      ringClaimerAddress: RING_CLAIMER_ADDRESS[SupportedChainId.BLAST],
      endTime: '1726947651',
    },
    {
      tokens: [FEW_WRAPPED_NATIVE_CURRENCY[SupportedChainId.BLAST] as Token, FEW_WBTC[SupportedChainId.BLAST]],
      originalTokens: [
        WRAPPED_NATIVE_CURRENCY[SupportedChainId.BLAST] as Token,
        FEW_ORIGINAL_WBTC[SupportedChainId.BLAST],
      ],
      index: 7,
      key: 17,
      stakingRewardAddress: '0xeff87a51f5abd015f1afcd5737bbab450ea15a24',
      ringClaimerAddress: RING_CLAIMER_ADDRESS[SupportedChainId.BLAST],
      endTime: '1726947651',
    },
    {
      tokens: [FEW_WRAPPED_NATIVE_CURRENCY[SupportedChainId.BLAST] as Token, FEW_ORBIT[SupportedChainId.BLAST]],
      originalTokens: [
        WRAPPED_NATIVE_CURRENCY[SupportedChainId.BLAST] as Token,
        FEW_ORIGINAL_ORBIT[SupportedChainId.BLAST],
      ],
      index: 8,
      key: 18,
      stakingRewardAddress: '0xeff87a51f5abd015f1afcd5737bbab450ea15a24',
      ringClaimerAddress: RING_CLAIMER_ADDRESS[SupportedChainId.BLAST],
      endTime: '1726947651',
    },
    {
      tokens: [FEW_WRAPPED_NATIVE_CURRENCY[SupportedChainId.BLAST] as Token, FEW_DETH[SupportedChainId.BLAST]],
      originalTokens: [
        WRAPPED_NATIVE_CURRENCY[SupportedChainId.BLAST] as Token,
        FEW_ORIGINAL_DETH[SupportedChainId.BLAST],
      ],
      index: 9,
      key: 19,
      stakingRewardAddress: '0xeff87a51f5abd015f1afcd5737bbab450ea15a24',
      ringClaimerAddress: RING_CLAIMER_ADDRESS[SupportedChainId.BLAST],
      endTime: '1726947651',
    },
    {
      tokens: [FEW_USDB[SupportedChainId.BLAST], FEW_DUSD[SupportedChainId.BLAST]],
      originalTokens: [FEW_ORIGINAL_USDB[SupportedChainId.BLAST], FEW_ORIGINAL_DUSD[SupportedChainId.BLAST]],
      index: 10,
      key: 20,
      stakingRewardAddress: '0xeff87a51f5abd015f1afcd5737bbab450ea15a24',
      ringClaimerAddress: RING_CLAIMER_ADDRESS[SupportedChainId.BLAST],
      endTime: '1726947651',
    },
    {
      tokens: [FEW_WRAPPED_NATIVE_CURRENCY[SupportedChainId.BLAST] as Token, FEW_RING[SupportedChainId.BLAST]],
      originalTokens: [
        WRAPPED_NATIVE_CURRENCY[SupportedChainId.BLAST] as Token,
        FEW_ORIGINAL_RING[SupportedChainId.BLAST],
      ],
      index: 13,
      key: 22,
      stakingRewardAddress: '0xeff87a51f5abd015f1afcd5737bbab450ea15a24',
      ringClaimerAddress: RING_CLAIMER_ADDRESS[SupportedChainId.BLAST],
      endTime: '1726947651',
    },
  ],
}

// eslint-disable-next-line import/no-unused-modules
export const STAKING_REWARDS_TOKEN_INFO: {
  [chainId: number]: {
    token: Token | Currency
    originalToken: Token | Currency
    index: number
    key: number
    stakingRewardAddress: string
    ringClaimerAddress: string
    endTime: string
  }[]
} = {
  168587773: [
    {
      token: nativeOnChain(SupportedChainId.BLAST_SEPOLIA),
      originalToken: nativeOnChain(SupportedChainId.BLAST_SEPOLIA),
      index: 2,
      key: 3,
      stakingRewardAddress: '0x8c90691F362B798d3bAFD3c68469f8aF5d5bBeED',
      ringClaimerAddress: RING_CLAIMER_ADDRESS[SupportedChainId.BLAST_SEPOLIA],
      endTime: '1707442335',
    },
    {
      token: WRAPPED_NATIVE_CURRENCY[SupportedChainId.BLAST_SEPOLIA] as Token,
      originalToken: WRAPPED_NATIVE_CURRENCY[SupportedChainId.BLAST_SEPOLIA] as Token,
      index: 2,
      key: 4,
      stakingRewardAddress: '0x8c90691F362B798d3bAFD3c68469f8aF5d5bBeED',
      ringClaimerAddress: RING_CLAIMER_ADDRESS[SupportedChainId.BLAST_SEPOLIA],
      endTime: '1707442335',
    },
  ],
}

// eslint-disable-next-line import/no-unused-modules
export const NEW_STAKING_REWARDS_TOKEN_INFO: {
  [chainId: number]: {
    token: Token | Currency
    originalToken: Token | Currency
    index: number
    key: number
    stakingRewardAddress: string
    ringClaimerAddress: string
    endTime: string
  }[]
} = {
  168587773: [
    {
      token: nativeOnChain(SupportedChainId.BLAST_SEPOLIA),
      originalToken: nativeOnChain(SupportedChainId.BLAST_SEPOLIA),
      index: 1,
      key: 5,
      stakingRewardAddress: '0x366Ac78214aFE145Ca35d4A6513F4eD9e8909Fe8',
      ringClaimerAddress: RING_CLAIMER_ADDRESS[SupportedChainId.BLAST_SEPOLIA],
      endTime: '1708927280',
    },
    {
      token: WRAPPED_NATIVE_CURRENCY[SupportedChainId.BLAST_SEPOLIA] as Token,
      originalToken: WRAPPED_NATIVE_CURRENCY[SupportedChainId.BLAST_SEPOLIA] as Token,
      index: 1,
      key: 6,
      stakingRewardAddress: '0x366Ac78214aFE145Ca35d4A6513F4eD9e8909Fe8',
      ringClaimerAddress: RING_CLAIMER_ADDRESS[SupportedChainId.BLAST_SEPOLIA],
      endTime: '1708927280',
    },
  ],
  81457: [
    {
      token: nativeOnChain(SupportedChainId.BLAST),
      originalToken: nativeOnChain(SupportedChainId.BLAST),
      index: 1,
      key: 10,
      stakingRewardAddress: '0xeff87a51f5abd015f1afcd5737bbab450ea15a24',
      ringClaimerAddress: RING_CLAIMER_ADDRESS[SupportedChainId.BLAST],
      endTime: '1726947651',
    },
    {
      token: WRAPPED_NATIVE_CURRENCY[SupportedChainId.BLAST] as Token,
      originalToken: WRAPPED_NATIVE_CURRENCY[SupportedChainId.BLAST] as Token,
      index: 1,
      key: 11,
      stakingRewardAddress: '0xeff87a51f5abd015f1afcd5737bbab450ea15a24',
      ringClaimerAddress: RING_CLAIMER_ADDRESS[SupportedChainId.BLAST],
      endTime: '1726947651',
    },
    {
      token: USDB_BLAST,
      originalToken: USDB_BLAST,
      index: 2,
      key: 12,
      stakingRewardAddress: '0xeff87a51f5abd015f1afcd5737bbab450ea15a24',
      ringClaimerAddress: RING_CLAIMER_ADDRESS[SupportedChainId.BLAST],
      endTime: '1726947651',
    },
  ],
  11155111: [
    {
      token: nativeOnChain(SupportedChainId.SEPOLIA),
      originalToken: nativeOnChain(SupportedChainId.SEPOLIA),
      index: 2,
      key: 7,
      stakingRewardAddress: '0x3f6d0620d655Dc618Cb956cc2073644f92736616',
      ringClaimerAddress: RING_CLAIMER_ADDRESS[SupportedChainId.SEPOLIA],
      endTime: '1719334988',
    },
    {
      token: WRAPPED_NATIVE_CURRENCY[SupportedChainId.SEPOLIA] as Token,
      originalToken: WRAPPED_NATIVE_CURRENCY[SupportedChainId.SEPOLIA] as Token,
      index: 2,
      key: 8,
      stakingRewardAddress: '0x3f6d0620d655Dc618Cb956cc2073644f92736616',
      ringClaimerAddress: RING_CLAIMER_ADDRESS[SupportedChainId.SEPOLIA],
      endTime: '1719334988',
    },
  ],
}

export const BLAST_GOLD_STAKING_REWARDS_TOKEN_INFO: {
  [chainId: number]: {
    token: Token | Currency
    originalToken: Token | Currency
    index: number
    key: number
    stakingRewardAddress: string
    ringClaimerAddress: string
    endTime: string
  }[]
} = {
  81457: [
    {
      token: RING[ChainId.BLAST],
      originalToken: RING[ChainId.BLAST],
      index: 14,
      key: 14,
      stakingRewardAddress: '0xEff87A51f5Abd015F1AFCD5737BBab450eA15A24',
      ringClaimerAddress: RING_CLAIMER_ADDRESS[SupportedChainId.BLAST],
      endTime: '1751266800',
    },
  ],
}

// eslint-disable-next-line import/no-unused-modules
export const FEW_WRAP_TOKEN_INFO: {
  [chainId: number]: {
    fewToken: Token | Currency
    originalToken: Token | Currency
    key: number
    fewWrappedTokenAddress: string
  }[]
} = {
  168587773: [
    {
      fewToken: FEW_WRAPPED_NATIVE_CURRENCY[SupportedChainId.BLAST_SEPOLIA] as Token,
      originalToken: nativeOnChain(SupportedChainId.BLAST_SEPOLIA),
      key: 1,
      fewWrappedTokenAddress: '0xCd31E6C1AD4c9E0E7F6A1f44583C038eaAaCf53F',
    },
    {
      fewToken: FEW_USDB[SupportedChainId.BLAST_SEPOLIA] as Token,
      originalToken: USDB_BLAST_SEPOLIA,
      key: 2,
      fewWrappedTokenAddress: '0x4D2c669Cf7DC804641Cd4Dec3CD73daB5575c3Da',
    },
    {
      fewToken: FEW_WRAPPED_NATIVE_CURRENCY[SupportedChainId.BLAST_SEPOLIA] as Token,
      originalToken: WRAPPED_NATIVE_CURRENCY[SupportedChainId.BLAST_SEPOLIA] as Token,
      key: 7,
      fewWrappedTokenAddress: '0x798dE0520497E28E8eBfF0DF1d791c2E942eA881',
    },
  ],
  81457: [
    {
      fewToken: FEW_WRAPPED_NATIVE_CURRENCY[SupportedChainId.BLAST] as Token,
      originalToken: nativeOnChain(SupportedChainId.BLAST),
      key: 3,
      fewWrappedTokenAddress: '0xF272a4b0d949011f9347134088126277abeB065F',
    },
    {
      fewToken: FEW_USDB[SupportedChainId.BLAST] as Token,
      originalToken: USDB_BLAST,
      key: 4,
      fewWrappedTokenAddress: '0x866f2C06B83Df2ed7Ca9C2D044940E7CD55a06d6',
    },
    {
      fewToken: FEW_WRAPPED_NATIVE_CURRENCY[SupportedChainId.BLAST] as Token,
      originalToken: WRAPPED_NATIVE_CURRENCY[SupportedChainId.BLAST] as Token,
      key: 8,
      fewWrappedTokenAddress: '0x66714DB8F3397c767d0A602458B5b4E3C0FE7dd1',
    },
  ],
  11155111: [
    {
      fewToken: FEW_WRAPPED_NATIVE_CURRENCY[SupportedChainId.SEPOLIA] as Token,
      originalToken: nativeOnChain(SupportedChainId.SEPOLIA),
      key: 5,
      fewWrappedTokenAddress: '0xC8D2bBBfDf6CED270d82aEa5961fE28608490F9f',
    },
    {
      fewToken: FEW_DAI[SupportedChainId.SEPOLIA] as Token,
      originalToken: DAI_SEPOLIA,
      key: 6,
      fewWrappedTokenAddress: '0x09D8486e42Aa76229a563bFa0f07CA301aCd29C9',
    },
    {
      fewToken: FEW_WRAPPED_NATIVE_CURRENCY[SupportedChainId.SEPOLIA] as Token,
      originalToken: WRAPPED_NATIVE_CURRENCY[SupportedChainId.SEPOLIA] as Token,
      key: 9,
      fewWrappedTokenAddress: '0x98b902eF4f9fEB2F6982ceEB4E98761294854D61',
    },
  ],
  1: [
    {
      fewToken: FEW_WRAPPED_NATIVE_CURRENCY[SupportedChainId.MAINNET] as Token,
      originalToken: nativeOnChain(SupportedChainId.MAINNET),
      key: 11,
      fewWrappedTokenAddress: '0xAda6059b4F6244Acd8934095Ed0162C5Df6B5ebB',
    },
    {
      fewToken: FEW_DAI[SupportedChainId.MAINNET] as Token,
      originalToken: DAI,
      key: 12,
      fewWrappedTokenAddress: '0x8A6fe57C08C84e0f4eE97aAe68a62e820a37d259',
    },
    {
      fewToken: FEW_USDT[SupportedChainId.MAINNET] as Token,
      originalToken: USDT,
      key: 13,
      fewWrappedTokenAddress: '0xef87f4608e601E8564800265AeE1c1FfaDF73283',
    },
    {
      fewToken: FEW_USDC[SupportedChainId.MAINNET] as Token,
      originalToken: USDC_MAINNET,
      key: 14,
      fewWrappedTokenAddress: '0x0492560FA7Cfd6A85E50D8bE3F77318994F8f429',
    },
    {
      fewToken: FEW_WRAPPED_NATIVE_CURRENCY[SupportedChainId.MAINNET] as Token,
      originalToken: WRAPPED_NATIVE_CURRENCY[SupportedChainId.MAINNET] as Token,
      key: 15,
      fewWrappedTokenAddress: '0xa250CC729Bb3323e7933022a67B52200fE354767',
    },
  ],
}

export const AIRDROP_ACTIVITY_INFOS = {
  [AirdropType.GALXE]: {
    projectName: 'Galxe Ring Quest',
    tokenName: 'Ring Point',
    tokenTotalSupply: '1,000,000',
  },
  [AirdropType.BAC_GAMES]: {
    projectName: 'OKX DEX Trading Campaign',
    tokenName: 'Ring Point',
    tokenTotalSupply: '500,000',
  },
  [AirdropType.RING_SNAPSHOTS]: {
    projectName: 'Testnet Mining Campaign',
    tokenName: 'Ring Point',
    tokenTotalSupply: '500,000',
  },
  [AirdropType.DAP_DAP]: {
    projectName: 'DapDap Campaign',
    tokenName: 'Ring Point',
    tokenTotalSupply: '100000',
  },
  [AirdropType.BAC_GAMES_2]: {
    projectName: 'OKX DEX Trading Campaign 2',
    tokenName: 'Ring Point',
    tokenTotalSupply: '100000',
  },
  [AirdropType.DISCORD]: {
    projectName: 'Discord Roles (OG/Vanguard/Soldier)',
    tokenName: 'Ring Point',
    tokenTotalSupply: '802000',
  },
  [AirdropType.BITGET]: {
    projectName: 'Bitget Wallet Campaign',
    tokenName: 'Ring Point',
    tokenTotalSupply: '301800',
  },
  [AirdropType.DUO]: {
    projectName: 'Duo Exchange Campaign',
    tokenName: 'Ring Point',
    tokenTotalSupply: '3498027',
  },
  [AirdropType.GALXE_EARN_LIQUIDITY]: {
    projectName: 'Galxe Earn Liquidity Campaign',
    tokenName: 'Ring Point',
    tokenTotalSupply: '103028',
  },
  [AirdropType.ORBIT_AND_PAC]: {
    projectName: 'Pac x Orbit Lending Airdrop',
    tokenName: 'Ring Point',
    tokenTotalSupply: '501332',
  },
}

export type AirdropTokenData = {
  [key in AirdropType]: {
    airdropToken: Token | Currency
    merkleDistributorWithDeadlineAddress: string
    endTime: string
  }
}

export const AIRDROP_TOKENS_INFO: {
  [chainId: number]: AirdropTokenData
} = {
  [ChainId.BLAST_SEPOLIA]: {
    [AirdropType.GALXE]: {
      airdropToken: POINT[SupportedChainId.BLAST_SEPOLIA] as Token,
      merkleDistributorWithDeadlineAddress: POINT_MERKLE_DISTRIBUTOR_ADDRESS[AirdropType.GALXE][ChainId.BLAST_SEPOLIA],
      endTime: '',
    },
    [AirdropType.RING_SNAPSHOTS]: {
      airdropToken: POINT[SupportedChainId.BLAST_SEPOLIA] as Token,
      merkleDistributorWithDeadlineAddress:
        POINT_MERKLE_DISTRIBUTOR_ADDRESS[AirdropType.RING_SNAPSHOTS][ChainId.BLAST_SEPOLIA],
      endTime: '',
    },
    [AirdropType.BAC_GAMES]: {
      airdropToken: POINT[SupportedChainId.BLAST_SEPOLIA] as Token,
      merkleDistributorWithDeadlineAddress:
        POINT_MERKLE_DISTRIBUTOR_ADDRESS[AirdropType.BAC_GAMES][ChainId.BLAST_SEPOLIA],
      endTime: '',
    },
    [AirdropType.DAP_DAP]: {
      airdropToken: POINT[SupportedChainId.BLAST_SEPOLIA] as Token,
      merkleDistributorWithDeadlineAddress:
        POINT_MERKLE_DISTRIBUTOR_ADDRESS[AirdropType.DAP_DAP][ChainId.BLAST_SEPOLIA],
      endTime: '',
    },
    [AirdropType.BAC_GAMES_2]: {
      airdropToken: POINT[SupportedChainId.BLAST_SEPOLIA] as Token,
      merkleDistributorWithDeadlineAddress:
        POINT_MERKLE_DISTRIBUTOR_ADDRESS[AirdropType.BAC_GAMES_2][ChainId.BLAST_SEPOLIA],
      endTime: '',
    },
    [AirdropType.DISCORD]: {
      airdropToken: POINT[SupportedChainId.BLAST_SEPOLIA] as Token,
      merkleDistributorWithDeadlineAddress:
        POINT_MERKLE_DISTRIBUTOR_ADDRESS[AirdropType.DISCORD][ChainId.BLAST_SEPOLIA],
      endTime: '',
    },
    [AirdropType.BITGET]: {
      airdropToken: POINT[SupportedChainId.BLAST_SEPOLIA] as Token,
      merkleDistributorWithDeadlineAddress: POINT_MERKLE_DISTRIBUTOR_ADDRESS[AirdropType.BITGET][ChainId.BLAST_SEPOLIA],
      endTime: '',
    },
    [AirdropType.DUO]: {
      airdropToken: POINT[SupportedChainId.BLAST_SEPOLIA] as Token,
      merkleDistributorWithDeadlineAddress: POINT_MERKLE_DISTRIBUTOR_ADDRESS[AirdropType.BITGET][ChainId.BLAST_SEPOLIA],
      endTime: '',
    },
    [AirdropType.GALXE_EARN_LIQUIDITY]: {
      airdropToken: POINT[SupportedChainId.BLAST_SEPOLIA] as Token,
      merkleDistributorWithDeadlineAddress:
        POINT_MERKLE_DISTRIBUTOR_ADDRESS[AirdropType.GALXE_EARN_LIQUIDITY][ChainId.BLAST_SEPOLIA],
      endTime: '',
    },
    [AirdropType.ORBIT_AND_PAC]: {
      airdropToken: POINT[SupportedChainId.BLAST_SEPOLIA] as Token,
      merkleDistributorWithDeadlineAddress:
        POINT_MERKLE_DISTRIBUTOR_ADDRESS[AirdropType.ORBIT_AND_PAC][ChainId.BLAST_SEPOLIA],
      endTime: '',
    },
  },
  [ChainId.BLAST]: {
    [AirdropType.GALXE]: {
      airdropToken: POINT[SupportedChainId.BLAST] as Token,
      merkleDistributorWithDeadlineAddress: POINT_MERKLE_DISTRIBUTOR_ADDRESS[AirdropType.GALXE][ChainId.BLAST],
      endTime: '1726842732',
    },
    [AirdropType.RING_SNAPSHOTS]: {
      airdropToken: POINT[SupportedChainId.BLAST] as Token,
      merkleDistributorWithDeadlineAddress: POINT_MERKLE_DISTRIBUTOR_ADDRESS[AirdropType.RING_SNAPSHOTS][ChainId.BLAST],
      endTime: '1726842746',
    },
    [AirdropType.BAC_GAMES]: {
      airdropToken: POINT[SupportedChainId.BLAST] as Token,
      merkleDistributorWithDeadlineAddress: POINT_MERKLE_DISTRIBUTOR_ADDRESS[AirdropType.BAC_GAMES][ChainId.BLAST],
      endTime: '1726735147',
    },
    [AirdropType.DAP_DAP]: {
      airdropToken: POINT[SupportedChainId.BLAST] as Token,
      merkleDistributorWithDeadlineAddress: POINT_MERKLE_DISTRIBUTOR_ADDRESS[AirdropType.DAP_DAP][ChainId.BLAST],
      endTime: '1727170229',
    },
    [AirdropType.BAC_GAMES_2]: {
      airdropToken: POINT[SupportedChainId.BLAST] as Token,
      merkleDistributorWithDeadlineAddress: POINT_MERKLE_DISTRIBUTOR_ADDRESS[AirdropType.BAC_GAMES_2][ChainId.BLAST],
      endTime: '1727768702',
    },
    [AirdropType.DISCORD]: {
      airdropToken: POINT[SupportedChainId.BLAST] as Token,
      merkleDistributorWithDeadlineAddress: POINT_MERKLE_DISTRIBUTOR_ADDRESS[AirdropType.DISCORD][ChainId.BLAST],
      endTime: '1728057720',
    },
    [AirdropType.BITGET]: {
      airdropToken: POINT[SupportedChainId.BLAST] as Token,
      merkleDistributorWithDeadlineAddress: POINT_MERKLE_DISTRIBUTOR_ADDRESS[AirdropType.BITGET][ChainId.BLAST],
      endTime: '1728294163',
    },
    [AirdropType.DUO]: {
      airdropToken: POINT[SupportedChainId.BLAST] as Token,
      merkleDistributorWithDeadlineAddress: POINT_MERKLE_DISTRIBUTOR_ADDRESS[AirdropType.DUO][ChainId.BLAST],
      endTime: '1728899299',
    },
    [AirdropType.GALXE_EARN_LIQUIDITY]: {
      airdropToken: POINT[SupportedChainId.BLAST] as Token,
      merkleDistributorWithDeadlineAddress:
        POINT_MERKLE_DISTRIBUTOR_ADDRESS[AirdropType.GALXE_EARN_LIQUIDITY][ChainId.BLAST],
      endTime: '1729499112',
    },
    [AirdropType.ORBIT_AND_PAC]: {
      airdropToken: POINT[SupportedChainId.BLAST] as Token,
      merkleDistributorWithDeadlineAddress: POINT_MERKLE_DISTRIBUTOR_ADDRESS[AirdropType.ORBIT_AND_PAC][ChainId.BLAST],
      endTime: '1731236905',
    },
  },
  [ChainId.SEPOLIA]: {
    [AirdropType.GALXE]: {
      airdropToken: POINT[SupportedChainId.SEPOLIA] as Token,
      merkleDistributorWithDeadlineAddress: POINT_MERKLE_DISTRIBUTOR_ADDRESS[AirdropType.GALXE][ChainId.SEPOLIA],
      endTime: '1719147318',
    },
    [AirdropType.RING_SNAPSHOTS]: {
      airdropToken: POINT[SupportedChainId.SEPOLIA] as Token,
      merkleDistributorWithDeadlineAddress:
        POINT_MERKLE_DISTRIBUTOR_ADDRESS[AirdropType.RING_SNAPSHOTS][ChainId.SEPOLIA],
      endTime: '1719147339',
    },
    [AirdropType.BAC_GAMES]: {
      airdropToken: POINT[SupportedChainId.SEPOLIA] as Token,
      merkleDistributorWithDeadlineAddress: POINT_MERKLE_DISTRIBUTOR_ADDRESS[AirdropType.BAC_GAMES][ChainId.SEPOLIA],
      endTime: '1719147808',
    },
    [AirdropType.DAP_DAP]: {
      airdropToken: POINT[SupportedChainId.SEPOLIA] as Token,
      merkleDistributorWithDeadlineAddress: POINT_MERKLE_DISTRIBUTOR_ADDRESS[AirdropType.DAP_DAP][ChainId.SEPOLIA],
      endTime: '1719479385',
    },
    [AirdropType.BAC_GAMES_2]: {
      airdropToken: POINT[SupportedChainId.SEPOLIA] as Token,
      merkleDistributorWithDeadlineAddress: POINT_MERKLE_DISTRIBUTOR_ADDRESS[AirdropType.BAC_GAMES_2][ChainId.SEPOLIA],
      endTime: '1720080870',
    },
    [AirdropType.DISCORD]: {
      airdropToken: POINT[SupportedChainId.SEPOLIA] as Token,
      merkleDistributorWithDeadlineAddress: POINT_MERKLE_DISTRIBUTOR_ADDRESS[AirdropType.DISCORD][ChainId.SEPOLIA],
      endTime: '1720367725',
    },
    [AirdropType.BITGET]: {
      airdropToken: POINT[SupportedChainId.SEPOLIA] as Token,
      merkleDistributorWithDeadlineAddress: POINT_MERKLE_DISTRIBUTOR_ADDRESS[AirdropType.BITGET][ChainId.SEPOLIA],
      endTime: '1720604366',
    },
    [AirdropType.DUO]: {
      airdropToken: POINT[SupportedChainId.SEPOLIA] as Token,
      merkleDistributorWithDeadlineAddress: POINT_MERKLE_DISTRIBUTOR_ADDRESS[AirdropType.BITGET][ChainId.SEPOLIA],
      endTime: '1721295605',
    },
    [AirdropType.GALXE_EARN_LIQUIDITY]: {
      airdropToken: POINT[SupportedChainId.SEPOLIA] as Token,
      merkleDistributorWithDeadlineAddress:
        POINT_MERKLE_DISTRIBUTOR_ADDRESS[AirdropType.GALXE_EARN_LIQUIDITY][ChainId.SEPOLIA],
      endTime: '1721982895',
    },
    [AirdropType.ORBIT_AND_PAC]: {
      airdropToken: POINT[SupportedChainId.SEPOLIA] as Token,
      merkleDistributorWithDeadlineAddress:
        POINT_MERKLE_DISTRIBUTOR_ADDRESS[AirdropType.ORBIT_AND_PAC][ChainId.SEPOLIA],
      endTime: '1723720261',
    },
  },
}

export interface StakingInfo {
  // the address of the reward contract
  stakingRewardAddress: string
  // the tokens involved in this pair
  tokens: [Token, Token]
  token?: Token
  originalTokens: [Token, Token]
  originalToken?: Token
  // the index of token
  index: number
  // the amount of token currently staked, or undefined if no account
  stakedAmount: CurrencyAmount<Token>
  // the amount of reward token earned by the active account, or undefined if no account
  earnedAmount: CurrencyAmount<Token>
  claimedAmount: CurrencyAmount<Token>
  // the total amount of token staked in the contract
  totalStakedAmount: CurrencyAmount<Token>
  // the amount of token distributed per second to all LPs, constant
  totalRewardRate: CurrencyAmount<Token>
  // the current amount of token distributed to the active account per second.
  // equivalent to percent of total supply * reward rate
  rewardRate: CurrencyAmount<Token>
  // when the period ends
  periodFinish?: Date
  // if pool is active
  active: boolean
  // calculates a hypothetical amount of token distributed to the active account per second.
  getHypotheticalRewardRate: (
    stakedAmount: CurrencyAmount<Token>,
    totalRewardRate: CurrencyAmount<Token>
  ) => CurrencyAmount<Token>
  key: number
}

// eslint-disable-next-line import/no-unused-modules
export interface StakingTokenInfo {
  // the address of the reward contract
  stakingRewardAddress: string
  // the tokens involved in this pair
  token: Token | Currency
  originalToken: Token | Currency
  // the index of token
  index: number
  // the amount of token currently staked, or undefined if no account
  stakedAmount: CurrencyAmount<Token | Currency>
  // the amount of reward token earned by the active account, or undefined if no account
  earnedAmount: CurrencyAmount<Token | Currency>
  claimedAmount: CurrencyAmount<Token | Currency>
  // the total amount of token staked in the contract
  totalStakedAmount: CurrencyAmount<Token | Currency>
  // the amount of token distributed per second to all LPs, constant
  totalRewardRate: CurrencyAmount<Token | Currency>
  // the current amount of token distributed to the active account per second.
  // equivalent to percent of total supply * reward rate
  rewardRate: CurrencyAmount<Token | Currency>
  // when the period ends
  periodFinish?: Date
  // if pool is active
  active: boolean
  // calculates a hypothetical amount of token distributed to the active account per second.
  getHypotheticalRewardRate: (
    stakedAmount: CurrencyAmount<Token | Currency>,
    totalRewardRate: CurrencyAmount<Token | Currency>
  ) => CurrencyAmount<Token | Currency>
  key: number
}

export interface FewWrappedTokenInfo {
  fewToken: Token | Currency
  originalToken: Token | Currency
  key: number
  fewWrappedTokenAddress: string
}

export interface AirdropTokenInfo {
  merkleDistributorWithDeadlineAddress: string
  airdropToken: Token | Currency
  airdropProof: any
  airdropType: AirdropType
  index?: number
  airdropAmount: CurrencyAmount<Currency>
  hasAirdrop: boolean
  activity: string
  endTime?: Date
}

// gets the staking info from the network for the active chain id
export function useStakingInfo(pairToFilterBy?: Pair | null): StakingInfo[] {
  const { chainId, account } = useWeb3React()

  const currentBlockTimestamp = useCurrentBlockTimestamp()
  // const fewStakingReward = useFewStakingContract()

  const info = useMemo(
    () =>
      chainId
        ? STAKING_REWARDS_INFO[chainId]?.filter((stakingRewardInfo) =>
            pairToFilterBy === undefined
              ? true
              : pairToFilterBy === null
              ? false
              : pairToFilterBy.involvesToken(stakingRewardInfo.tokens[0]) &&
                pairToFilterBy.involvesToken(stakingRewardInfo.tokens[1])
          ) ?? []
        : [],
    [chainId, pairToFilterBy]
  )

  const rgb = chainId ? RGB[chainId] : undefined

  const accountArgs = useMemo(() => {
    if (info.length > 0 && account) {
      return info.map(({ index }) => [index, account])
    } else {
      return []
    }
  }, [info, account])

  const indexArg = useMemo(() => {
    if (info.length > 0) {
      return info.map(({ index }) => [index])
    } else {
      return []
    }
  }, [info])
  // const accountArgs = useMemo(() => [account ?? undefined], [account])

  const rewardsAddresses = useMemo(() => info.map(({ stakingRewardAddress }) => stakingRewardAddress), [info])
  const claimerAddresses = useMemo(() => info.map(({ ringClaimerAddress }) => ringClaimerAddress), [info])

  const infoIndex = useMemo(() => info.map(({ index }) => index), [info])

  const balances = useMultipleContractMultipleData(rewardsAddresses, FEW_STAKING_REWARDS_INTERFACE, 'balanceOf', [
    accountArgs,
  ])

  const earnedAmounts = useMultipleContractMultipleData(rewardsAddresses, FEW_STAKING_REWARDS_INTERFACE, 'earned', [
    accountArgs,
  ])

  const claimedAmounts = useMultipleContractMultipleData(claimerAddresses, RING_TOKEN_CLAIMER_INTERFACE, 'getClaimed', [
    accountArgs,
  ])

  const totalSupplies = useMultipleContractMultipleData(
    rewardsAddresses,
    FEW_STAKING_REWARDS_INTERFACE,
    'totalSupply',
    [indexArg]
  )

  // tokens per second, constants
  const rewardRates = useMultipleContractMultipleData(
    rewardsAddresses,
    FEW_STAKING_REWARDS_INTERFACE,
    'rewardPerTokenPerSecond',
    [indexArg]
  )

  const periodFinishes = useMultipleContractMultipleData(
    rewardsAddresses,
    FEW_STAKING_REWARDS_INTERFACE,
    'periodFinish',
    [indexArg]
  )

  return useMemo(() => {
    if (!chainId || !rgb) return []

    return infoIndex.reduce<StakingInfo[]>((memo, rewardsAddress, index) => {
      // these two are dependent on account
      const balanceState = balances[0][index]
      const earnedAmountState = earnedAmounts[0][index]
      const claimedAmountState = claimedAmounts[0][index]

      // these get fetched regardless of account
      const totalSupplyState = totalSupplies[0][index]
      const rewardRateState = rewardRates[0][index]
      const periodFinishState = periodFinishes[0][index]

      if (
        // these may be undefined if not logged in
        !balanceState?.loading &&
        !earnedAmountState?.loading &&
        !claimedAmountState?.loading &&
        // always need these
        totalSupplyState &&
        !totalSupplyState.loading &&
        rewardRateState &&
        !rewardRateState.loading &&
        periodFinishState &&
        !periodFinishState.loading
      ) {
        if (
          balanceState?.error ||
          earnedAmountState?.error ||
          claimedAmountState?.error ||
          totalSupplyState.error ||
          rewardRateState.error ||
          periodFinishState.error
        ) {
          console.error('Failed to load staking rewards info')
          return memo
        }

        // get the LP token
        const tokens = info[index].tokens
        const dummyPair = new Pair(
          CurrencyAmount.fromRawAmount(tokens[0], '0'),
          CurrencyAmount.fromRawAmount(tokens[1], '0')
        )

        // check for account, if no account set to 0

        const stakedAmount = CurrencyAmount.fromRawAmount(
          dummyPair.liquidityToken,
          JSBI.BigInt(balanceState?.result?.[0] ?? 0)
        )
        const totalStakedAmount = CurrencyAmount.fromRawAmount(
          dummyPair.liquidityToken,
          JSBI.BigInt(totalSupplyState.result?.[0])
        )
        const totalRewardRate = CurrencyAmount.fromRawAmount(rgb, JSBI.BigInt(rewardRateState.result?.[0]))

        const getHypotheticalRewardRate = (
          stakedAmount: CurrencyAmount<Token>,
          totalRewardRate: CurrencyAmount<Token>
        ): CurrencyAmount<Token> => {
          return CurrencyAmount.fromRawAmount(
            rgb,
            JSBI.greaterThan(stakedAmount.quotient, JSBI.BigInt(0))
              ? JSBI.divide(
                  JSBI.multiply(totalRewardRate.quotient, stakedAmount.quotient),
                  JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(18))
                )
              : JSBI.BigInt(0)
          )
        }

        const individualRewardRate = getHypotheticalRewardRate(stakedAmount, totalRewardRate)

        const periodFinishSeconds = Number(periodFinishState.result?.[0])
        const periodFinishMs = periodFinishSeconds * 1000

        // compare period end timestamp vs current block timestamp (in seconds)
        const active =
          periodFinishSeconds && currentBlockTimestamp ? periodFinishSeconds > currentBlockTimestamp.toNumber() : true

        memo.push({
          stakingRewardAddress: info[index].stakingRewardAddress,
          tokens: info[index].tokens,
          originalTokens: info[index].originalTokens,
          index: infoIndex[0],
          periodFinish: periodFinishMs > 0 ? new Date(periodFinishMs) : undefined,
          earnedAmount: CurrencyAmount.fromRawAmount(rgb, JSBI.BigInt(earnedAmountState?.result?.[0] ?? 0)),
          claimedAmount: CurrencyAmount.fromRawAmount(rgb, JSBI.BigInt(claimedAmountState?.result?.[0] ?? 0)),
          rewardRate: individualRewardRate,
          totalRewardRate,
          stakedAmount,
          totalStakedAmount,
          getHypotheticalRewardRate,
          active,
          key: info[index].key,
        })
      }
      return memo
    }, [])
  }, [
    chainId,
    rgb,
    infoIndex,
    balances,
    earnedAmounts,
    claimedAmounts,
    totalSupplies,
    rewardRates,
    periodFinishes,
    info,
    currentBlockTimestamp,
  ])
}

// gets the staking info from the network for the active chain id
export function useNewStakingInfo(pairToFilterBy?: Pair | null): StakingInfo[] {
  const { chainId, account } = useWeb3React()

  const currentBlockTimestamp = useCurrentBlockTimestamp()
  // const fewStakingReward = useFewStakingContract()

  const info = useMemo(
    () =>
      chainId
        ? NEW_STAKING_REWARDS_INFO[chainId]?.filter((stakingRewardInfo) =>
            pairToFilterBy === undefined
              ? true
              : pairToFilterBy === null
              ? false
              : pairToFilterBy.involvesToken(stakingRewardInfo.tokens[0]) &&
                pairToFilterBy.involvesToken(stakingRewardInfo.tokens[1])
          ) ?? []
        : [],
    [chainId, pairToFilterBy]
  )

  const rgb = chainId === ChainId.BLAST ? POINT[ChainId.BLAST] : RING[chainId ?? ChainId.BLAST_SEPOLIA]

  const accountArgs = useMemo(() => {
    if (info.length > 0 && account) {
      return info.map(({ index }) => [index, account])
    } else {
      return []
    }
  }, [info, account])

  const indexArg = useMemo(() => {
    if (info.length > 0) {
      return info.map(({ index }) => [index])
    } else {
      return []
    }
  }, [info])
  // const accountArgs = useMemo(() => [account ?? undefined], [account])

  const rewardsAddresses = useMemo(() => info.map(({ stakingRewardAddress }) => stakingRewardAddress), [info])
  const claimerAddresses = useMemo(() => info.map(({ ringClaimerAddress }) => ringClaimerAddress), [info])

  const infoIndex = useMemo(() => info.map(({ index }) => index), [info])

  const balances = useMultipleContractMultipleData(rewardsAddresses, FEW_STAKING_REWARDS_INTERFACE, 'balanceOf', [
    accountArgs,
  ])

  const earnedAmounts = useMultipleContractMultipleData(rewardsAddresses, FEW_STAKING_REWARDS_INTERFACE, 'earned', [
    accountArgs,
  ])

  const claimedAmounts = useMultipleContractMultipleData(claimerAddresses, RING_TOKEN_CLAIMER_INTERFACE, 'getClaimed', [
    accountArgs,
  ])

  const totalSupplies = useMultipleContractMultipleData(
    rewardsAddresses,
    FEW_STAKING_REWARDS_INTERFACE,
    'totalSupply',
    [indexArg]
  )

  // tokens per second, constants
  const rewardRates = useMultipleContractMultipleData(
    rewardsAddresses,
    FEW_STAKING_REWARDS_INTERFACE,
    'rewardPerTokenPerSecond',
    [indexArg]
  )

  const periodFinishes = useMultipleContractMultipleData(
    rewardsAddresses,
    FEW_STAKING_REWARDS_INTERFACE,
    'periodFinish',
    [indexArg]
  )

  return useMemo(() => {
    if (!chainId || !rgb) return []

    return infoIndex.reduce<StakingInfo[]>((memo, rewardsAddress, index) => {
      // these two are dependent on account
      const balanceState = balances[0][index]
      const earnedAmountState = earnedAmounts[0][index]
      const claimedAmountState = claimedAmounts[0][index]
      // these get fetched regardless of account
      const totalSupplyState = totalSupplies[0][index]
      const rewardRateState = rewardRates[0][index]
      const periodFinishState = periodFinishes[0][index]

      if (
        // these may be undefined if not logged in
        !balanceState?.loading &&
        !earnedAmountState?.loading &&
        !claimedAmountState?.loading &&
        // always need these
        totalSupplyState &&
        !totalSupplyState.loading &&
        rewardRateState &&
        !rewardRateState.loading &&
        periodFinishState &&
        !periodFinishState.loading
      ) {
        if (
          balanceState?.error ||
          earnedAmountState?.error ||
          claimedAmountState?.error ||
          totalSupplyState.error ||
          rewardRateState.error
        ) {
          console.error('Failed to load staking rewards info')
          return memo
        }

        // get the LP token
        const tokens = info[index].tokens
        const dummyPair = new Pair(
          CurrencyAmount.fromRawAmount(tokens[0], '0'),
          CurrencyAmount.fromRawAmount(tokens[1], '0')
        )

        const stakedAmount = CurrencyAmount.fromRawAmount(
          dummyPair.liquidityToken,
          JSBI.BigInt(balanceState?.result?.[0] ?? 0)
        )
        const totalStakedAmount = CurrencyAmount.fromRawAmount(
          dummyPair.liquidityToken,
          JSBI.BigInt(totalSupplyState.result?.[0])
        )
        const totalRewardRate = CurrencyAmount.fromRawAmount(rgb, JSBI.BigInt(rewardRateState.result?.[0]))

        const getHypotheticalRewardRate = (
          stakedAmount: CurrencyAmount<Token>,
          totalRewardRate: CurrencyAmount<Token>
        ): CurrencyAmount<Token> => {
          return CurrencyAmount.fromRawAmount(
            rgb,
            JSBI.greaterThan(stakedAmount.quotient, JSBI.BigInt(0))
              ? JSBI.divide(
                  JSBI.multiply(totalRewardRate.quotient, stakedAmount.quotient),
                  JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(18))
                )
              : JSBI.BigInt(0)
          )
        }

        const individualRewardRate = getHypotheticalRewardRate(stakedAmount, totalRewardRate)

        const periodFinishSeconds = Number(periodFinishState.result?.[0])
        const periodFinishMs = periodFinishSeconds * 1000

        // compare period end timestamp vs current block timestamp (in seconds)
        const active =
          periodFinishSeconds && currentBlockTimestamp ? periodFinishSeconds > currentBlockTimestamp.toNumber() : true

        memo.push({
          stakingRewardAddress: info[index].stakingRewardAddress,
          tokens: info[index].tokens,
          originalTokens: info[index].originalTokens,
          index: infoIndex[0],
          periodFinish: periodFinishMs > 0 ? new Date(periodFinishMs) : undefined,
          earnedAmount: CurrencyAmount.fromRawAmount(rgb, JSBI.BigInt(earnedAmountState?.result?.[0] ?? 0)),
          claimedAmount: CurrencyAmount.fromRawAmount(rgb, JSBI.BigInt(claimedAmountState?.result?.[0] ?? 0)),
          rewardRate: individualRewardRate,
          totalRewardRate,
          stakedAmount,
          totalStakedAmount,
          getHypotheticalRewardRate,
          active,
          key: info[index].key,
        })
      }
      return memo
    }, [])
  }, [
    chainId,
    rgb,
    infoIndex,
    balances,
    earnedAmounts,
    claimedAmounts,
    totalSupplies,
    rewardRates,
    periodFinishes,
    info,
    currentBlockTimestamp,
  ])
}

// gets the staking info from the network for the active chain id
// eslint-disable-next-line import/no-unused-modules
export function useStakingTokenInfo(token?: string | null): StakingTokenInfo[] {
  const { chainId, account } = useWeb3React()

  const currentBlockTimestamp = useCurrentBlockTimestamp()
  // const fewStakingReward = useFewStakingContract()
  const originalToken = useCurrency(token)

  const quoteToken = token === 'ETH' ? nativeOnChain(chainId ?? ChainId.BLAST) : originalToken?.wrapped

  const info = useMemo(
    () =>
      chainId
        ? STAKING_REWARDS_TOKEN_INFO[chainId]?.filter((stakingRewardInfo) =>
            quoteToken ? stakingRewardInfo.originalToken.equals(quoteToken) : true
          ) ?? []
        : [],
    [chainId, quoteToken]
  )

  const rgb = chainId ? RGB[chainId] : undefined

  const rewardsAddresses = useMemo(() => info.map(({ stakingRewardAddress }) => stakingRewardAddress), [info])
  const claimerAddresses = useMemo(() => info.map(({ ringClaimerAddress }) => ringClaimerAddress), [info])

  const infoIndex = useMemo(() => info.map(({ index }) => index), [info])

  const accountArgs = useMemo(() => {
    if (info.length > 0 && account) {
      return info.map(({ index }) => [index, account])
    } else {
      return []
    }
  }, [info, account])

  const indexArg = useMemo(() => {
    if (info.length > 0) {
      return info.map(({ index }) => [index])
    } else {
      return []
    }
  }, [info])

  // const accountArgs = useMemo(() => [account ?? undefined], [account])

  const balances = useMultipleContractMultipleData(rewardsAddresses, FEW_STAKING_REWARDS_INTERFACE, 'balanceOf', [
    accountArgs,
  ])

  const earnedAmounts = useMultipleContractMultipleData(rewardsAddresses, FEW_STAKING_REWARDS_INTERFACE, 'earned', [
    accountArgs,
  ])

  const claimedAmounts = useMultipleContractMultipleData(claimerAddresses, RING_TOKEN_CLAIMER_INTERFACE, 'getClaimed', [
    accountArgs,
  ])

  const totalSupplies = useMultipleContractMultipleData(
    rewardsAddresses,
    FEW_STAKING_REWARDS_INTERFACE,
    'totalSupply',
    [indexArg]
  )

  // tokens per second, constants
  const rewardRates = useMultipleContractMultipleData(
    rewardsAddresses,
    FEW_STAKING_REWARDS_INTERFACE,
    'rewardPerTokenPerSecond',
    [indexArg]
  )

  const periodFinishes = useMultipleContractMultipleData(
    rewardsAddresses,
    FEW_STAKING_REWARDS_INTERFACE,
    'periodFinish',
    [indexArg]
  )

  return useMemo(() => {
    if (!chainId || !rgb) return []

    return infoIndex.reduce<StakingTokenInfo[]>((memo, rewardsAddress, index) => {
      // these two are dependent on account
      const balanceState = balances[0][index]
      const earnedAmountState = earnedAmounts[0][index]
      const claimedAmountState = claimedAmounts[0][index]

      // these get fetched regardless of account
      const totalSupplyState = totalSupplies[0][index]
      const rewardRateState = rewardRates[0][index]
      const periodFinishState = periodFinishes[0][index]

      if (
        // these may be undefined if not logged in
        !balanceState?.loading &&
        !earnedAmountState?.loading &&
        !claimedAmountState?.loading &&
        // always need these
        totalSupplyState &&
        !totalSupplyState.loading &&
        rewardRateState &&
        !rewardRateState.loading &&
        periodFinishState &&
        !periodFinishState.loading
      ) {
        if (
          balanceState?.error ||
          earnedAmountState?.error ||
          claimedAmountState?.error ||
          totalSupplyState.error ||
          rewardRateState.error ||
          periodFinishState.error
        ) {
          console.error('Failed to load staking rewards info')
          return memo
        }

        // get the LP token
        const token = info[index].token

        // check for account, if no account set to 0

        const stakedAmount = CurrencyAmount.fromRawAmount(token, JSBI.BigInt(balanceState?.result?.[0] ?? 0))
        const totalStakedAmount = CurrencyAmount.fromRawAmount(token, JSBI.BigInt(totalSupplyState.result?.[0]))
        const totalRewardRate = CurrencyAmount.fromRawAmount(rgb, JSBI.BigInt(rewardRateState.result?.[0]))

        const getHypotheticalRewardRate = (
          stakedAmount: CurrencyAmount<Token | Currency>,
          totalRewardRate: CurrencyAmount<Token | Currency>
        ): CurrencyAmount<Token | Currency> => {
          return CurrencyAmount.fromRawAmount(
            rgb,
            JSBI.greaterThan(stakedAmount.quotient, JSBI.BigInt(0))
              ? JSBI.divide(
                  JSBI.multiply(totalRewardRate.quotient, stakedAmount.quotient),
                  JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(18))
                )
              : JSBI.BigInt(0)
          )
        }

        const individualRewardRate = getHypotheticalRewardRate(stakedAmount.wrapped, totalRewardRate)

        const periodFinishSeconds = Number(periodFinishState.result?.[0])
        const periodFinishMs = periodFinishSeconds * 1000

        // compare period end timestamp vs current block timestamp (in seconds)
        const active =
          periodFinishSeconds && currentBlockTimestamp ? periodFinishSeconds > currentBlockTimestamp.toNumber() : true

        memo.push({
          stakingRewardAddress: info[index].stakingRewardAddress,
          token: info[index].token,
          originalToken: info[index].originalToken,
          index: info[index].index,
          periodFinish: periodFinishMs > 0 ? new Date(periodFinishMs) : undefined,
          earnedAmount: CurrencyAmount.fromRawAmount(rgb, JSBI.BigInt(earnedAmountState?.result?.[0] ?? 0)),
          claimedAmount: CurrencyAmount.fromRawAmount(rgb, JSBI.BigInt(claimedAmountState?.result?.[0] ?? 0)),
          rewardRate: individualRewardRate,
          totalRewardRate,
          stakedAmount,
          totalStakedAmount,
          getHypotheticalRewardRate,
          active,
          key: info[index].key,
        })
      }
      return memo
    }, [])
  }, [
    chainId,
    rgb,
    infoIndex,
    balances,
    earnedAmounts,
    claimedAmounts,
    totalSupplies,
    rewardRates,
    periodFinishes,
    info,
    currentBlockTimestamp,
  ])
}

export function useFewWrappedTokenInfo(token?: string | null): FewWrappedTokenInfo[] {
  const { chainId } = useWeb3React()

  // const fewStakingReward = useFewStakingContract()
  const originalToken = useCurrency(token)

  const quoteToken = token === 'ETH' ? nativeOnChain(chainId ?? ChainId.BLAST) : originalToken?.wrapped

  const info = useMemo(
    () =>
      chainId
        ? FEW_WRAP_TOKEN_INFO[chainId]?.filter((stakingRewardInfo) =>
            quoteToken ? stakingRewardInfo.originalToken.equals(quoteToken) : true
          ) ?? []
        : [],
    [chainId, quoteToken]
  )

  return info
}

export function useClaimTokenInfo(): AirdropTokenInfo[] {
  const { chainId, account } = useWeb3React()

  const claimData = useUserClaimData(account)

  const info: AirdropTokenData | undefined = useMemo(
    () => (chainId ? AIRDROP_TOKENS_INFO[chainId] : undefined),
    [chainId]
  )

  return useMemo(() => {
    if (!chainId || !info) return []

    const result = Object.keys(info).reduce<AirdropTokenInfo[]>((memo, airdrop) => {
      const airdropType = airdrop as AirdropType
      const airdropToken = AIRDROP_TOKENS_INFO[chainId][airdropType].airdropToken
      const airdropAmount = CurrencyAmount.fromRawAmount(
        airdropToken,
        JSBI.BigInt(claimData?.[airdropType].amount ?? 0)
      )

      const airdropProof = claimData?.[airdropType]?.proof
      const airdropIndex = claimData?.[airdropType]?.index
      const periodFinishSeconds = Number(AIRDROP_TOKENS_INFO[chainId][airdropType].endTime)
      const periodFinishMs = periodFinishSeconds * 1000
      const hasAirdrop = airdropAmount.greaterThan('0')

      memo.push({
        merkleDistributorWithDeadlineAddress: info[airdrop as AirdropType].merkleDistributorWithDeadlineAddress,
        airdropToken,
        airdropProof,
        airdropType,
        index: airdropIndex,
        airdropAmount,
        activity: AIRDROP_ACTIVITY_INFOS[airdrop as AirdropType].projectName,
        hasAirdrop,
        endTime: periodFinishMs > 0 ? new Date(periodFinishMs) : undefined,
      })

      return memo
    }, [])

    // Sort the array by endTime in descending order
    result.sort((a, b) => (b.endTime?.getTime() ?? 0) - (a.endTime?.getTime() ?? 0))

    return result
  }, [chainId, info, claimData])
}

// gets the staking info from the network for the active chain id
// eslint-disable-next-line import/no-unused-modules
export function useNewStakingTokenInfo(token?: string | null): StakingTokenInfo[] {
  const { chainId, account } = useWeb3React()

  const currentBlockTimestamp = useCurrentBlockTimestamp()
  // const fewStakingReward = useFewStakingContract()
  const originalToken = useCurrency(token)

  const quoteToken = token === 'ETH' ? nativeOnChain(chainId ?? ChainId.BLAST_SEPOLIA) : originalToken?.wrapped

  const info = useMemo(
    () =>
      chainId
        ? NEW_STAKING_REWARDS_TOKEN_INFO[chainId]?.filter((stakingRewardInfo) =>
            quoteToken ? stakingRewardInfo.originalToken.equals(quoteToken) : true
          ) ?? []
        : [],
    [chainId, quoteToken]
  )

  const rgb = chainId === ChainId.BLAST ? POINT[ChainId.BLAST] : RING[chainId ?? ChainId.BLAST_SEPOLIA]

  const rewardsAddresses = useMemo(() => info.map(({ stakingRewardAddress }) => stakingRewardAddress), [info])
  const claimerAddresses = useMemo(() => info.map(({ ringClaimerAddress }) => ringClaimerAddress), [info])

  const infoIndex = useMemo(() => info.map(({ index }) => index), [info])

  const accountArgs = useMemo(() => {
    if (info.length > 0 && account) {
      return info.map(({ index }) => [index, account])
    } else {
      return []
    }
  }, [info, account])

  const indexArg = useMemo(() => {
    if (info.length > 0) {
      return info.map(({ index }) => [index])
    } else {
      return []
    }
  }, [info])

  // const accountArgs = useMemo(() => [account ?? undefined], [account])

  const balances = useMultipleContractMultipleData(rewardsAddresses, FEW_STAKING_REWARDS_INTERFACE, 'balanceOf', [
    accountArgs,
  ])

  const earnedAmounts = useMultipleContractMultipleData(rewardsAddresses, FEW_STAKING_REWARDS_INTERFACE, 'earned', [
    accountArgs,
  ])

  const claimedAmounts = useMultipleContractMultipleData(claimerAddresses, RING_TOKEN_CLAIMER_INTERFACE, 'getClaimed', [
    accountArgs,
  ])

  const totalSupplies = useMultipleContractMultipleData(
    rewardsAddresses,
    FEW_STAKING_REWARDS_INTERFACE,
    'totalSupply',
    [indexArg]
  )

  // tokens per second, constants
  const rewardRates = useMultipleContractMultipleData(
    rewardsAddresses,
    FEW_STAKING_REWARDS_INTERFACE,
    'rewardPerTokenPerSecond',
    [indexArg]
  )

  const periodFinishes = useMultipleContractMultipleData(
    rewardsAddresses,
    FEW_STAKING_REWARDS_INTERFACE,
    'periodFinish',
    [indexArg]
  )

  return useMemo(() => {
    if (!chainId || !rgb) return []

    return infoIndex.reduce<StakingTokenInfo[]>((memo, rewardsAddress, index) => {
      // these two are dependent on account
      const balanceState = balances[0][index]
      const earnedAmountState = earnedAmounts[0][index]
      const claimedAmountState = claimedAmounts[0][index]

      // these get fetched regardless of account
      const totalSupplyState = totalSupplies[0][index]
      const rewardRateState = rewardRates[0][index]
      const periodFinishState = periodFinishes[0][index]

      if (
        // these may be undefined if not logged in
        !balanceState?.loading &&
        !earnedAmountState?.loading &&
        !claimedAmountState?.loading &&
        // always need these
        totalSupplyState &&
        !totalSupplyState.loading &&
        rewardRateState &&
        !rewardRateState.loading &&
        periodFinishState &&
        !periodFinishState.loading
      ) {
        if (
          balanceState?.error ||
          earnedAmountState?.error ||
          claimedAmountState?.error ||
          totalSupplyState.error ||
          rewardRateState.error ||
          periodFinishState.error
        ) {
          console.error('Failed to load staking rewards info')
          return memo
        }

        // get the LP token
        const token = info[index].token

        const stakedAmount = CurrencyAmount.fromRawAmount(token, JSBI.BigInt(balanceState?.result?.[0] ?? 0))
        const totalStakedAmount = CurrencyAmount.fromRawAmount(token, JSBI.BigInt(totalSupplyState.result?.[0]))
        const totalRewardRate = CurrencyAmount.fromRawAmount(rgb, JSBI.BigInt(rewardRateState.result?.[0]))

        const getHypotheticalRewardRate = (
          stakedAmount: CurrencyAmount<Token | Currency>,
          totalRewardRate: CurrencyAmount<Token | Currency>
        ): CurrencyAmount<Token | Currency> => {
          return CurrencyAmount.fromRawAmount(
            rgb,
            JSBI.greaterThan(stakedAmount.quotient, JSBI.BigInt(0))
              ? JSBI.divide(
                  JSBI.multiply(totalRewardRate.quotient, stakedAmount.quotient),
                  JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(18))
                )
              : JSBI.BigInt(0)
          )
        }

        const individualRewardRate = getHypotheticalRewardRate(stakedAmount.wrapped, totalRewardRate)

        const periodFinishSeconds = Number(periodFinishState.result?.[0])
        const periodFinishMs = periodFinishSeconds * 1000

        // compare period end timestamp vs current block timestamp (in seconds)
        const active =
          periodFinishSeconds && currentBlockTimestamp ? periodFinishSeconds > currentBlockTimestamp.toNumber() : true

        memo.push({
          stakingRewardAddress: info[index].stakingRewardAddress,
          token: info[index].token,
          originalToken: info[index].originalToken,
          index: info[index].index,
          periodFinish: periodFinishMs > 0 ? new Date(periodFinishMs) : undefined,
          earnedAmount: CurrencyAmount.fromRawAmount(rgb, JSBI.BigInt(earnedAmountState?.result?.[0] ?? 0)),
          claimedAmount: CurrencyAmount.fromRawAmount(rgb, JSBI.BigInt(claimedAmountState?.result?.[0] ?? 0)),
          rewardRate: individualRewardRate,
          totalRewardRate,
          stakedAmount,
          totalStakedAmount,
          getHypotheticalRewardRate,
          active,
          key: info[index].key,
        })
      }
      return memo
    }, [])
  }, [
    chainId,
    rgb,
    infoIndex,
    balances,
    earnedAmounts,
    claimedAmounts,
    totalSupplies,
    rewardRates,
    periodFinishes,
    info,
    currentBlockTimestamp,
  ])
}

export function useBlastGoldStakingTokenInfo(token?: string | null): StakingTokenInfo[] {
  const { chainId, account } = useWeb3React()

  const currentBlockTimestamp = useCurrentBlockTimestamp()
  // const fewStakingReward = useFewStakingContract()
  const originalToken = useCurrency(token)

  const quoteToken = token === 'ETH' ? nativeOnChain(chainId ?? ChainId.BLAST_SEPOLIA) : originalToken?.wrapped

  const info = useMemo(
    () =>
      chainId
        ? BLAST_GOLD_STAKING_REWARDS_TOKEN_INFO[chainId]?.filter((stakingRewardInfo) =>
            quoteToken ? stakingRewardInfo.originalToken.equals(quoteToken) : true
          ) ?? []
        : [],
    [chainId, quoteToken]
  )

  const rgb = chainId === ChainId.BLAST ? POINT[ChainId.BLAST] : RING[chainId ?? ChainId.BLAST_SEPOLIA]

  const rewardsAddresses = useMemo(() => info.map(({ stakingRewardAddress }) => stakingRewardAddress), [info])
  const claimerAddresses = useMemo(() => info.map(({ ringClaimerAddress }) => ringClaimerAddress), [info])

  const infoIndex = useMemo(() => info.map(({ index }) => index), [info])

  const accountArgs = useMemo(() => {
    if (info.length > 0 && account) {
      return info.map(({ index }) => [index, account])
    } else {
      return []
    }
  }, [info, account])

  const indexArg = useMemo(() => {
    if (info.length > 0) {
      return info.map(({ index }) => [index])
    } else {
      return []
    }
  }, [info])

  // const accountArgs = useMemo(() => [account ?? undefined], [account])

  const balances = useMultipleContractMultipleData(rewardsAddresses, FEW_STAKING_REWARDS_INTERFACE, 'balanceOf', [
    accountArgs,
  ])

  const earnedAmounts = useMultipleContractMultipleData(rewardsAddresses, FEW_STAKING_REWARDS_INTERFACE, 'earned', [
    accountArgs,
  ])

  const claimedAmounts = useMultipleContractMultipleData(claimerAddresses, RING_TOKEN_CLAIMER_INTERFACE, 'getClaimed', [
    accountArgs,
  ])

  const totalSupplies = useMultipleContractMultipleData(
    rewardsAddresses,
    FEW_STAKING_REWARDS_INTERFACE,
    'totalSupply',
    [indexArg]
  )

  // tokens per second, constants
  const rewardRates = useMultipleContractMultipleData(
    rewardsAddresses,
    FEW_STAKING_REWARDS_INTERFACE,
    'rewardPerTokenPerSecond',
    [indexArg]
  )

  const periodFinishes = useMultipleContractMultipleData(
    rewardsAddresses,
    FEW_STAKING_REWARDS_INTERFACE,
    'periodFinish',
    [indexArg]
  )

  return useMemo(() => {
    if (!chainId || !rgb) return []

    return infoIndex.reduce<StakingTokenInfo[]>((memo, rewardsAddress, index) => {
      // these two are dependent on account
      const balanceState = balances[0][index]
      const earnedAmountState = earnedAmounts[0][index]
      const claimedAmountState = claimedAmounts[0][index]

      // these get fetched regardless of account
      const totalSupplyState = totalSupplies[0][index]
      const rewardRateState = rewardRates[0][index]
      const periodFinishState = periodFinishes[0][index]

      if (
        // these may be undefined if not logged in
        !balanceState?.loading &&
        !earnedAmountState?.loading &&
        !claimedAmountState?.loading &&
        // always need these
        totalSupplyState &&
        !totalSupplyState.loading &&
        rewardRateState &&
        !rewardRateState.loading &&
        periodFinishState &&
        !periodFinishState.loading
      ) {
        if (
          balanceState?.error ||
          earnedAmountState?.error ||
          claimedAmountState?.error ||
          totalSupplyState.error ||
          rewardRateState.error ||
          periodFinishState.error
        ) {
          console.error('Failed to load staking rewards info')
          return memo
        }

        // get the LP token
        const token = info[index].token

        const stakedAmount = CurrencyAmount.fromRawAmount(token, JSBI.BigInt(balanceState?.result?.[0] ?? 0))
        const totalStakedAmount = CurrencyAmount.fromRawAmount(token, JSBI.BigInt(totalSupplyState.result?.[0]))
        const totalRewardRate = CurrencyAmount.fromRawAmount(rgb, JSBI.BigInt(rewardRateState.result?.[0]))

        const getHypotheticalRewardRate = (
          stakedAmount: CurrencyAmount<Token | Currency>,
          totalRewardRate: CurrencyAmount<Token | Currency>
        ): CurrencyAmount<Token | Currency> => {
          return CurrencyAmount.fromRawAmount(
            rgb,
            JSBI.greaterThan(stakedAmount.quotient, JSBI.BigInt(0))
              ? JSBI.divide(
                  JSBI.multiply(totalRewardRate.quotient, stakedAmount.quotient),
                  JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(18))
                )
              : JSBI.BigInt(0)
          )
        }

        const individualRewardRate = getHypotheticalRewardRate(stakedAmount.wrapped, totalRewardRate)

        const periodFinishSeconds = Number(periodFinishState.result?.[0])
        const periodFinishMs = periodFinishSeconds * 1000

        // compare period end timestamp vs current block timestamp (in seconds)
        const active =
          periodFinishSeconds && currentBlockTimestamp ? periodFinishSeconds > currentBlockTimestamp.toNumber() : true

        memo.push({
          stakingRewardAddress: info[index].stakingRewardAddress,
          token: info[index].token,
          originalToken: info[index].originalToken,
          index: info[index].index,
          periodFinish: periodFinishMs > 0 ? new Date(periodFinishMs) : undefined,
          earnedAmount: CurrencyAmount.fromRawAmount(rgb, JSBI.BigInt(earnedAmountState?.result?.[0] ?? 0)),
          claimedAmount: CurrencyAmount.fromRawAmount(rgb, JSBI.BigInt(claimedAmountState?.result?.[0] ?? 0)),
          rewardRate: individualRewardRate,
          totalRewardRate,
          stakedAmount,
          totalStakedAmount,
          getHypotheticalRewardRate,
          active,
          key: info[index].key,
        })
      }
      return memo
    }, [])
  }, [
    chainId,
    rgb,
    infoIndex,
    balances,
    earnedAmounts,
    claimedAmounts,
    totalSupplies,
    rewardRates,
    periodFinishes,
    info,
    currentBlockTimestamp,
  ])
}

// based on typed value
export function useDerivedStakeInfo(
  typedValue: string,
  stakingToken: Token | Currency | undefined,
  userLiquidityUnstaked: CurrencyAmount<Token | Currency> | undefined
): {
  parsedAmount?: CurrencyAmount<Token | Currency>
  error?: ReactNode
} {
  const { account } = useWeb3React()

  const parsedInput: CurrencyAmount<Token | Currency> | undefined = tryParseCurrencyAmount(typedValue, stakingToken)

  const parsedAmount =
    parsedInput && userLiquidityUnstaked && JSBI.lessThanOrEqual(parsedInput.quotient, userLiquidityUnstaked.quotient)
      ? parsedInput
      : undefined

  let error: ReactNode | undefined
  if (!account) {
    error = <Trans>Connect Wallet</Trans>
  }
  if (!parsedAmount) {
    error = error ?? <Trans>Enter an amount</Trans>
  }

  return {
    parsedAmount,
    error,
  }
}

// based on typed value
export function useDerivedWrapInfo(
  typedValue: string,
  stakingToken: Token | Currency | undefined,
  userLiquidityUnstaked: CurrencyAmount<Token | Currency> | undefined
): {
  parsedAmount?: CurrencyAmount<Token | Currency>
  error?: ReactNode
} {
  const { account } = useWeb3React()

  const parsedInput: CurrencyAmount<Token | Currency> | undefined = tryParseCurrencyAmount(typedValue, stakingToken)

  const parsedAmount =
    parsedInput && userLiquidityUnstaked && JSBI.lessThanOrEqual(parsedInput.quotient, userLiquidityUnstaked.quotient)
      ? parsedInput
      : undefined

  let error: ReactNode | undefined
  if (!account) {
    error = <Trans>Connect Wallet</Trans>
  }
  if (!parsedAmount) {
    error = error ?? <Trans>Enter an amount</Trans>
  }

  return {
    parsedAmount,
    error,
  }
}

// based on typed value
// eslint-disable-next-line import/no-unused-modules
export function useDerivedRedeemInfo(
  typedValue: string,
  redeemToken: Token | Currency | undefined,
  userBalances: CurrencyAmount<Token | Currency> | undefined
): {
  parsedAmount?: CurrencyAmount<Token | Currency>
  error?: ReactNode
} {
  const { account } = useWeb3React()

  const parsedInput: CurrencyAmount<Token | Currency> | undefined = tryParseCurrencyAmount(typedValue, redeemToken)

  const parsedAmount =
    parsedInput && userBalances && JSBI.lessThanOrEqual(parsedInput.quotient, userBalances.quotient)
      ? parsedInput
      : undefined

  let error: ReactNode | undefined
  if (!account) {
    error = <Trans>Connect Wallet</Trans>
  }
  if (!parsedAmount) {
    error = error ?? <Trans>Enter an amount</Trans>
  }

  return {
    parsedAmount,
    error,
  }
}
